<template>
  <alert-modal
    :visible="value"
    @close="close"
    @yes="yes"
    :message="message"
    text="会員情報ページへ戻る"
  />
</template>

<script>
import AlertModal from './Common/AlertModal.vue'

export default {
  components: { AlertModal },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String
    }
  },
  methods: {
    close () {
      this.$emit('input', false)
    },
    yes () {
      this.$router.push({ name: 'member-info' })
    }
  }
}
</script>
