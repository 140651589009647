<template>
  <common-modal
    :visible="isVisible"
    @close="close"
    :is-persistent="true"
  >
    <div class="px-5 pb-4">
      <div class="mb-4"><slot /></div>

      <div class="text-center mb-4">
        <v-btn
          rounded
          width="262"
          height="57"
          class="bg-silver --prm text-medium--w-bold mb-2"
          @click="yes"
          elevation="0"
        >
          {{ yesText }}
        </v-btn>

        <v-btn
          rounded
          width="262"
          height="57"
          class="text-medium--w-bold --c-prm"
          color="btn-grad--orange"
          @click="no"
          elevation="0"
        >
          {{ noText }}
        </v-btn>
      </div>
    </div>
  </common-modal>
</template>

<script>
import CommonModal from './index.vue'

export default {
  name: 'YesNoModal',
  components: { CommonModal },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    yesText: {
      type: String,
      default: 'OK'
    },
    noText: {
      type: String,
      default: 'キャンセル'
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.close()
        }
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    yes () {
      this.$emit('yes')
    },
    no () {
      this.$emit('no')
    }
  }
}
</script>
