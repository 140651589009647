<template>
  <div>
    <Breadcrumbs>
      <router-link to="/home/member-info">
        {{ $t("menu.main.membershipInformation") }}
      </router-link>
      >
      {{ $route.meta.title }}
    </Breadcrumbs>
    <div class="form-container px-5 pb-15">
      <h1 class="text-heading--c-prm text-center py-6">
        {{ $t("memberInfo.changeMFAPolicy") }}
      </h1>

      <v-form ref="form" lazy-validation class="bg-white rounded px-5 py-5">
        <v-radio-group v-model="mfaPolicy">
          <v-radio value="SAVE_BROWSER" label="シンプルモード" class="primary-radio" />
          <div class="mb-3 ml-4">
            ログイン手続きが簡素化され、IDとパスワードの入力のみでログインできます。
          </div>
          <v-radio value="ALWAYS" label="二段階認証モード（推奨）" class="primary-radio" />
          <div class="mb-3 ml-4">
            IDとパスワードを入力後、IDとして登録されたアドレスにメールが送信され、
            そのメールに記載されたURLをクリックすることでログインできます。
          </div>
        </v-radio-group>
        <div class="text-medium">
          ※認証モードはいつでも変更いただけます。
        </div>
      </v-form>

      <v-btn
        elevation="0"
        @click="dialog = true"
        x-large
        rounded
        block
        class="mt-3"
        color="btn-grad--orange"
      >
        {{ $t("buttons.change") }}
      </v-btn>
    </div>
    <yes-no-dialog
      :visible="dialog"
      yesText="変更する"
      noText="戻る"
      @yes="submitForm"
      @no="dialog = false"
      @close="dialog = false"
    >
      {{ modeName }}モードに変更します。よろしいですか？
    </yes-no-dialog>
    <return-to-member-info-modal v-model="done" :message="`${modeName}モードに変更しました`" />
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import YesNoDialog from '@/components/Modal/Common/YesNoModal'
import ReturnToMemberInfoModal from '@/components/Modal/ReturnToMemberInfoModal'

export default {
  name: 'MFAPolicy',
  components: {
    Breadcrumbs,
    YesNoDialog,
    ReturnToMemberInfoModal
  },
  data () {
    return {
      mfaPolicy: null,
      dialog: false,
      done: false
    }
  },
  computed: {
    modeName () {
      if (this.mfaPolicy === 'ALWAYS') {
        return '二段階認証'
      } else if (this.mfaPolicy === 'SAVE_BROWSER') {
        return 'シンプル'
      }
      return ''
    }
  },
  methods: {
    async submitForm () {
      if (this.$refs.form.validate()) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            await this.$store.dispatch('updateMfaPolicy', {
              mfaPolicy: this.mfaPolicy
            })

            this.done = true
          })
        })
        this.dialog = false
      }
    }
  },
  async mounted () {
    const result = await this.$store.dispatch('myClientInfo', {
      apollo: this.$apollo
    })
    this.mfaPolicy = result.data.myClientInfo.mfaPolicy
  }
}
</script>
