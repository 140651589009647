<template>
  <common-modal
    :visible="isVisible"
    @close="close"
    :is-persistent="true"
  >
    <div class="px-5 pb-4">
      <div class="mb-4"><slot>{{ message }}</slot></div>

      <div class="text-center mb-4">
        <v-btn
          rounded
          width="262"
          height="57"
          class="bg-silver --prm text-medium--w-bold mb-2"
          @click="yes"
          elevation="0"
        >
          {{ text }}
        </v-btn>
      </div>
    </div>
  </common-modal>
</template>

<script>
import CommonModal from './index.vue'

export default {
  name: 'AlertModal',
  components: { CommonModal },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'OK'
    },
    message: {
      type: String,
      default: ''
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.close()
        }
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    yes () {
      this.$emit('yes')
    }
  }
}
</script>
